import React from 'react';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import skinsStyle from './styles/skins.scss';

const BasicLine: React.FC<SkinFiveGridLineProps> = ({ skin, ...rest }) => {
  return <FiveGridLineWrapper {...rest} className={skinsStyle[skin]} />;
};

export default BasicLine;
